import React from 'react';
import './ButtonPrimary.css';

class ButtonPrimary extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        if(this.props.disabled){
            return(
                <button type="submit" className="buttonPrimary mt3 fsizeSmall bcPrimary fcThird" disabled>{this.props.text}</button>
            )    
        }
        return(
            <button type="submit" className="buttonPrimary mt3 fsizeSmall bcPrimary fcThird">{this.props.text}</button>
        )
    }
}

export default ButtonPrimary;