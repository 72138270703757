import React from 'react';
import ModuleItem from '../DashboardModule/DashboardModule';
import './Modules.css';

class Modules extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            modulos: this.props.modulos,
            progresso: this.props.progresso
        }

        this.setModules = this.setModules.bind(this);
    }

    setModules(moduleItem){
        if(this.state.progresso){
            if(this.state.progresso.progresso_modulos.length > 0){
                var progresso_modulos = this.state.progresso.progresso_modulos;
                for (let i = 0; i < progresso_modulos.length; i++) {
                    if(moduleItem._id === progresso_modulos[i].id_modulo){
                        return (<ModuleItem key={moduleItem._id} modulo={moduleItem} progresso={progresso_modulos[i]}/> )    
                    }   
                }
                return (<ModuleItem key={moduleItem._id} modulo={moduleItem} progresso={undefined}/> )
            } else {
                return (<ModuleItem key={moduleItem._id} modulo={moduleItem} progresso={undefined}/> )   
            }
        }else{
            return (<ModuleItem key={moduleItem._id} modulo={moduleItem} progresso={undefined}/> )
        }
    }

    render(){
        return (
            <>
                <div className='modules-course-header'>
                    <h1 className="mb2">Desenvolvimento Infantil do Lactente</h1>
                </div>   
                <div className="modules-wrapper">
                    {this.state.modulos.map((moduleItem)=>(
                        this.setModules(moduleItem)
                    ))}
                </div>
            </>
        );  
    }
}

export default Modules;