import React from 'react';
import './ModuleFinalized.css';
import { Link, withRouter } from 'react-router-dom';
import staticConfig from '../../../constants';
import insertAuth from '../../../services/insertAuth';
import imgBanner from '../../../Image/wid_vertical_branco_colorido.png';
import imgStamp from '../../../Image/icon-stamp.png';
import api from '../../../services/api';
import getLoggedUser from "../../../services/getLoggedUser";

class ScreenModuleFinalized extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            usuario: {},
            modulos: [],
            id_finalizado: this.props.match.params.id_finalizado,
            id_proximo: this.props.match.params.id_proximo,
            modulo_finalizado: undefined
        }

        this.setFinalizado = this.setFinalizado.bind(this);
    }

    componentDidMount(){
        let self = this;
        getLoggedUser(function(userID){
            if(userID !== null){
                api.get('/estudante/'+userID).then(res => {
                    self.setState({ usuario: res.data});
                });
            }else{
                self.props.history.push("/");
            }
        });
        api.get('/curso/'+staticConfig.course_id+'/modulo', insertAuth()).then(res => {
            const modulos_response = res.data;
            this.setState(() => ({
                modulos: modulos_response
            }), () => (
                this.setFinalizado()
            ));
        });
        
    }

    setFinalizado(){
        for (let i = 0; i < this.state.modulos.length; i++) {
            if(this.state.modulos[i]._id === this.state.id_finalizado) {
                this.setState(() => ({
                    modulo_finalizado: this.state.modulos[i]
                }))
                break;
            }
        }
    }

    render(){
        return(
            <div className="module-finalized-wrapper">
                <img className="logo-top-medium" src={imgBanner}></img>
                <div className="module-finalized-inner">
                    <img className="icon-stamp" src={imgStamp}></img>
                    <div className="module-finalized-text">
                        <h1>Parabéns {this.state.usuario && <b>{this.state.usuario.nome}</b>}</h1>
                        {this.state.modulo_finalizado && <h2>Você finalizou o <b>{this.state.modulo_finalizado.nome} - {this.state.modulo_finalizado.descricao}</b> com sucesso!</h2>}
                    </div>
                    
                </div>
                <Link to={'/conteudo/'+this.state.id_proximo} className="link-button-1 mt3 fsizeSmall">CONTINUAR</Link>
            </div>
        )
    }
}

export default withRouter(ScreenModuleFinalized);