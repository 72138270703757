import React from 'react';
import { withRouter } from "react-router-dom";
import './ForgotPassword.css';
import UIButtonPrimary from '../../../Components/UI/ButtonPrimary/ButtonPrimary';
import api from '../../../services/api';

class PanelForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            error: '',
            enviado: false,
            enviando: false,
            input_errors: {
                email: ''   
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.blankFields = this.blankFields.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        if(!this.blankFields()){
            var email = { email: this.state.email}
            this.setState(({ enviando: true }), () => {
                api.post('/auth/forgot_password', email).then(res => {
                    if(res.status === 201){
                        this.setState({
                            enviando: false,
                            enviado: true
                        }, () => {
                            setTimeout(function(){
                                this.setState({enviado: false});
                            }.bind(this),3000); 
                        });  
                    } else {
                        this.setState({
                            error: "Erro ao redefinir senha, por favor tente novamente"
                        })     
                    }
                }).catch((error) => {
                    this.setState({
                        enviando: false,
                        error: error.response.data.error
                    });
                });   
            }); 
                    
        } else {
            this.setState({
                error: "Por favor preencha o email para redefinir a senha"
            })
        }
    }

    blankFields(){
        if(!this.state.email){
            this.setState({
                input_errors: {
                    email:  !this.state.email
                }
            })
            return true;
        }
        return false;
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState(() => ({
          [name]: value,
          error: '',
          input_errors: {
            [name]: ''
          }
        }));
    }

    render(){
        return(
            <div className="panel-forgot">
                <div className="panel-forgot-header">
                    <h1>REDEFINIR SENHA</h1>
                    <h4>Informe seu email cadastrado que enviaremos um link para a redefinição de senha</h4>
                </div>
                {this.state.error && <div className="error-warn">
                    <p>{this.state.error}</p>
                </div>}
                {this.state.enviado && <div className="success-warn">
                    <p>Email de redefinição enviado!</p>
                </div>}
                {this.state.enviando && <div className="success-warn">
                    <p>Enviando email de redefinição...</p>
                </div>}                 
                <form onSubmit={this.handleSubmit} className="panel-forgot-fields">
                    <div className={this.state.input_errors.email  ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-forgot icon-forgot-email"></span><h1>Email:</h1>
                        </div>
                        <div className="input-2-field">
                            <input name="email" type="email" value={this.state.email} onChange={this.handleInputChange}/> 
                        </div>
                    </div>
                    <div className="panel-forgot-button">
                        <UIButtonPrimary text="CONTINUAR"/>
                    </div>
                </form> 
                <div className="panel-forgot-footer">
                    <h1>Ainda não possui cadastro? <a href="/cadastro">Cadastre-se</a></h1>
                    <h1>Já possui cadastro? <a href="/">Faça login</a></h1>
                </div>               
            </div>
        )
    }
}

export default withRouter(PanelForgotPassword);