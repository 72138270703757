import React from 'react';
import './ForgotPassword.css';

import imgBanner from '../../../Image/wid_vertical_branco_colorido.png'; 

import PanelForgotPassword from '../../../Components/Student/ForgotPassword/ForgotPassword';

class ScreenStudentForgotPassword extends React.Component{
    render(){
        return(
            <div className="forgot-page-wrapper">
                <div className="forgot-page-banner">
                    <img src={imgBanner}></img>
                    <h1>Desenvolvimento infantil do Lactente de <br/>
                        um jeito dinâmico, interativo e inovador</h1>
                </div>
                <PanelForgotPassword/>
            </div>
        )
    }
}

export default ScreenStudentForgotPassword;