import React from 'react';
import './ResetPassword.css';

import imgBanner from '../../../Image/wid_vertical_branco_colorido.png'; 

import PanelResetPassword from '../../../Components/Student/ResetPassword/ResetPassword';

class ScreenStudentResetPassword extends React.Component{
    render(){
        return(
            <div className="reset-page-wrapper">
                <div className="reset-page-banner">
                    <img src={imgBanner}></img>
                    <h1>Desenvolvimento infantil do Lactente de <br/>
                        um jeito dinâmico, interativo e inovador</h1>
                </div>
                <PanelResetPassword/>
            </div>
        )
    }
}

export default ScreenStudentResetPassword;