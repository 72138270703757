import React from 'react';
import { Page, Text, Image, Font, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import ptbr from 'moment/locale/pt-br';

class CertificateDocument extends React.Component{
    constructor(props){
        super(props);

        this.formatPeriod = this.formatPeriod.bind(this);
    }

    formatPeriod(estudante){
        var localBegin = moment(estudante.criado_em);
        localBegin.locale('pt-br', ptbr);
        var localEnd = moment(estudante.certificado.data_emissao);
        localEnd.locale('pt-br', ptbr);
        var begin;
        var end;
        if(localBegin.year() === localEnd.year()){
            if(localBegin.month() === localEnd.month()){
                begin = localBegin.format('DD [a] ').toString();
                end = localEnd.format('DD [de] MMMM [de] YYYY').toString();
                return begin + end;    
            } else {
                begin = localBegin.format('DD [de] MMMM [a] ').toString();
                end = localEnd.format('DD [de] MMMM [de] YYYY').toString();
                return begin + end;
            }
        } else {
            begin = localBegin.format('DD [de] MMMM [de] YYYY [a] ').toString();
            end = localEnd.format('DD [de] MMMM [de] YYYY').toString();
            return begin + end;
        }
    }

    render(){
        if(this.props.curso && this.props.estudante && this.props.estudante.certificado){
            // Register font
            Font.register({ family: 'Raleway-Bold', src: this.props.curso.certificado_fonte_url});

            // Create styles
            const styles = StyleSheet.create({
                page: {
                    flexDirection: 'row',
                },
                name: {
                    position: 'absolute',
                    top: '8.7cm',
                    left: '6.4cm',
                    fontFamily: 'Raleway-Bold',
                    fontSize: '11pt'
                },
                period: {
                    position: 'absolute',
                    top: '10cm',
                    left: '9.8cm',
                    fontFamily: 'Raleway-Bold',
                    fontSize: '8.2pt'
                }
            });
            // Create Document Component
       
            var frente_img = this.props.curso.certificado_frente_url;
            var verso_img = this.props.curso.certificado_verso_url;
            return (
                <Document>
                    <Page size="A4" orientation="landscape">
                        <Image src={frente_img} />
                        <Text style={styles.name}>{this.props.estudante.nome}</Text>
                        <Text style={styles.period}>{this.formatPeriod(this.props.estudante)}</Text>
                        <Image src={verso_img} />  
                    </Page>
                </Document>
            );
        }
    }
            
        
}

export default CertificateDocument;