import React from 'react';
import './AdminTable.css';
import api from '../../services/api';
import staticConfig from '../../constants';
import logo from '../../Image/logo_wid_vertical_colorido.png';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import ptbr from 'moment/locale/pt-br';

class ScreenAdminTable extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            estudantes: [],
            modulos: []
        };

        this.formatProgressDetails = this.formatProgressDetails.bind(this);
        this.formatTotalProgress = this.formatTotalProgress.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }
    
    componentDidMount() {
        api.get('/admin/find/all').then(res => {
            this.setState({ estudantes: res.data});
        });
        api.get('/curso/'+staticConfig.course_id+'/modulo/admin').then(res => {
            this.setState({ modulos: res.data});
        });
    }

    formatProgressDetails(progresso, modulo_index){
        if(progresso && this.state.modulos.length > 0){
            if(progresso.progresso_modulos.length > 0){
                for (let index = 0; index < progresso.progresso_modulos.length; index++) {
                    if(progresso.progresso_modulos[index].id_modulo === this.state.modulos[modulo_index]._id){
                        let pro_modulo = progresso.progresso_modulos[index];
                        if(pro_modulo.progresso === 100){
                            return (
                                <>
                                    <div className="admin-module-progress-wrapper">
                                        <span className="admin-module-progress-icon-done"></span>
                                        <span className="admin-module-progress-numbers fcSixth">Concluído</span>
                                    </div>
                                    <div className="admin-module-progress-bar bcFourth">
                                        <div style={ { width: '100%' } } className="admin-module-progress-bar-progress bcSixth"></div>
                                    </div>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div className="admin-module-progress-wrapper">
                                        <span className="admin-module-progress-icon"></span>
                                        <span className="admin-module-progress-numbers">{pro_modulo.progresso_secoes.length} de {this.state.modulos[modulo_index].secoes.length}</span>
                                    </div>
                                    <div className="admin-module-progress-bar bcFourth">
                                        <div style={ { width: pro_modulo.progresso+'%' } } className="admin-module-progress-bar-progress bcPrimary"></div>
                                    </div>
                                </>
                            )
                        }
                    }
                }
                return (
                    <>
                        <div className="admin-module-progress-wrapper">
                                <span className="admin-module-progress-icon"></span>
                                <span className="admin-module-progress-numbers">0 de {this.state.modulos[modulo_index].secoes.length}</span>
                            </div>
                        <div className="admin-module-progress-bar bcFourth">
                            <div style={ { width: '0%' } } className="admin-module-progress-bar-progress bcPrimary"></div>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className="admin-module-progress-wrapper">
                                <span className="admin-module-progress-icon"></span>
                                <span className="admin-module-progress-numbers">0 de {this.state.modulos[modulo_index].secoes.length}</span>
                            </div>
                        <div className="admin-module-progress-bar bcFourth">
                            <div style={ { width: '0%' } } className="admin-module-progress-bar-progress bcPrimary"></div>
                        </div>
                    </>
                )
            }
        } else {
            return (
                <>
                    <div className="admin-module-progress-wrapper">
                            <span className="admin-module-progress-icon"></span>
                            <span className="admin-module-progress-numbers">0 de {this.state.modulos[modulo_index].secoes.length}</span>
                        </div>
                    <div className="admin-module-progress-bar bcFourth">
                        <div style={ { width: '0%' } } className="admin-module-progress-bar-progress bcPrimary"></div>
                    </div>
                </>
            )   
        }
    }

    formatTotalProgress(progresso){
        let total_secoes = 36;
        if(progresso){
            if(progresso.progresso_modulos.length > 0){
                if(progresso.progresso === 100){
                    return (
                        <>
                            <div className="admin-module-progress-wrapper">
                                <span className="admin-module-progress-icon-done"></span>
                                <span className="admin-module-progress-numbers fcSixth">Concluído</span>
                            </div>
                            <div className="admin-module-progress-bar bcFourth">
                                <div style={ { width: '100%' } } className="admin-module-progress-bar-progress bcSixth"></div>
                            </div>
                        </>
                    )    
                } else {
                    let secoes_concluidas = 0;
                    for (let index = 0; index < progresso.progresso_modulos.length; index++) {
                        secoes_concluidas += progresso.progresso_modulos[index].progresso_secoes.length;
                    }
                    return (
                        <>
                            <div className="admin-module-progress-wrapper">
                                <span className="admin-module-progress-icon"></span>
                                <span className="admin-module-progress-numbers">{secoes_concluidas} de {total_secoes}</span>
                            </div>
                            <div className="admin-module-progress-bar bcFourth">
                                <div style={ { width: progresso.progresso+'%' } } className="admin-module-progress-bar-progress bcPrimary"></div>
                            </div>
                        </>
                    )
                }
            } else {
                return (
                    <>
                        <div className="admin-module-progress-wrapper">
                                <span className="admin-module-progress-icon"></span>
                                <span className="admin-module-progress-numbers">0 de {total_secoes}</span>
                            </div>
                        <div className="admin-module-progress-bar bcFourth">
                            <div style={ { width: '0%' } } className="admin-module-progress-bar-progress bcPrimary"></div>
                        </div>
                    </>
                )
            }
        } else {
            return (
                <>
                    <div className="admin-module-progress-wrapper">
                            <span className="admin-module-progress-icon"></span>
                            <span className="admin-module-progress-numbers">0 de {total_secoes}</span>
                        </div>
                    <div className="admin-module-progress-bar bcFourth">
                        <div style={ { width: '0%' } } className="admin-module-progress-bar-progress bcPrimary"></div>
                    </div>
                </>
            )
        }
    }

    formatDate(data){
        var localMoment = moment(data);
        localMoment.locale('pt-br', ptbr);
        var dataOk = localMoment.format('DD MMMM YYYY').toString();
        return dataOk;
    }

    render(){ 
        return(
            <div className="admin-container-wrapper">
                <div className="admin-header">
                    <div className="admin-header-logo">
                        <img src={logo}></img>
                    </div>
                    <h1 className="admin-painel-name fcPrimary fsizeLarge">Painel de Administração</h1>
                    <span className="admin-exit-button fsizeXSmall">Sair</span>
                </div>
                <div className="admin-header-space">
                    &nbsp;
                </div>
                <div className="admin-tab-container">
                <table className="admin-tab">
                    <thead>
                        <tr className="admin-tab-head">
                            <th id="admin-tab-head-item-user-name" className="admin-tab-head-item">Nome do usuário</th>
                            <th className="admin-tab-head-item">Data de Nascimento</th>
                            <th className="admin-tab-head-item">Sexo</th>
                            <th className="admin-tab-head-item">Estado Civil</th>
                            <th className="admin-tab-head-item">Instituição de Ensino</th>
                            <th className="admin-tab-head-item">Semestre que está cursando</th>
                            <th className="admin-tab-head-item">Email</th>
                            <th className="admin-tab-head-item">Pré-Teste</th>
                            <th className="admin-tab-head-item">Pós-Teste</th>
                            {this.state.modulos.length > 0 && <>
                                <th className="admin-tab-head-item">
                                    <div className="admin-tab-head-item-module-name">{this.state.modulos[0].nome}</div>
                                    <div>{this.state.modulos[0].descricao}</div>
                                </th>
                                <th className="admin-tab-head-item">
                                    <div className="admin-tab-head-item-module-name">{this.state.modulos[0].nome}</div>
                                    <div>{this.state.modulos[1].descricao}</div>
                                </th>
                                <th className="admin-tab-head-item">
                                    <div className="admin-tab-head-item-module-name">{this.state.modulos[0].nome}</div>
                                    <div>{this.state.modulos[2].descricao}</div>
                                </th>
                                <th className="admin-tab-head-item">
                                    <div className="admin-tab-head-item-module-name">{this.state.modulos[0].nome}</div>
                                    <div>{this.state.modulos[3].descricao}</div>
                                </th>
                                <th className="admin-tab-head-item">
                                    <div className="admin-tab-head-item-module-name">{this.state.modulos[0].nome}</div>
                                    <div>{this.state.modulos[4].descricao}</div>
                                </th>
                            </>}
                            <th id="admin-tab-head-item-total" className="admin-tab-head-item">Total</th>    
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.estudantes.length > 0 && this.state.estudantes.map((estudante, index) => (
                            <tr key={index} className="admin-tab-data">
                                <th id="admin-tab-data-item-name" className="admin-tab-data-item">
                                    <div id="admin-tab-data-number">{index + 1}</div>
                                    <div id="admin-tab-data-name">{estudante.nome}</div>
                                </th>
                                <td className="admin-tab-data-item">{this.formatDate(estudante.data_nascimento)}</td>
                                <td className="admin-tab-data-item">{estudante.sexo}</td>
                                <td className="admin-tab-data-item">{estudante.estado_civil}</td>
                                <td className="admin-tab-data-item">{estudante.instituicao}</td>
                                <td className="admin-tab-data-item">{estudante.semestre}</td>
                                <td className="admin-tab-data-item">{estudante.usuario ? estudante.usuario.email : 'sem usuario'}</td>
                                <td className="admin-tab-data-item">{estudante.pre_teste_resposta ? estudante.pre_teste_resposta.acertos+"/"+estudante.pre_teste_resposta.total_respondido : "-"}</td>
                                <td className="admin-tab-data-item">{estudante.pos_teste_resposta ? estudante.pos_teste_resposta.acertos+"/"+estudante.pos_teste_resposta.total_respondido : "-"}</td>
                                <td className="admin-tab-data-item">{this.formatProgressDetails(estudante.progresso, 0)}</td>
                                <td className="admin-tab-data-item">{this.formatProgressDetails(estudante.progresso, 1)}</td>
                                <td className="admin-tab-data-item">{this.formatProgressDetails(estudante.progresso, 2)}</td>
                                <td className="admin-tab-data-item">{this.formatProgressDetails(estudante.progresso, 3)}</td>
                                <td className="admin-tab-data-item">{this.formatProgressDetails(estudante.progresso, 4)}</td>
                                <td className="admin-tab-data-item">{this.formatTotalProgress(estudante.progresso)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>
            </div>
        )
    }
}

export default withRouter(ScreenAdminTable);