import React from 'react';
import './MenuSection.css';
import staticConfig from '../../../constants';
import api from '../../../services/api';
import getLoggedUser from '../../../services/getLoggedUser';
import { withRouter } from "react-router-dom";

class MenuSection extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            secao: {},
            progresso_secoes: []
        }

        this.changeRoute = this.changeRoute.bind(this);
    }

    componentDidMount() {
        api.get('/curso/'+staticConfig.course_id+'/modulo/'+this.props.id_modulo+'/secao/'+this.props.id_secao).then(res => {
            const secao = res.data;
            this.setState({ secao });
        });
    }

    changeRoute(){
        this.props.updateStateParams(this.props.id_modulo, this.props.id_secao);
    }

    render(){
        if(this.props.secao_atual === this.props.id_secao){
            return(
                <div className="section-title-wrapper">
                    <span className="section-icon section-icon-status-selected"></span>
                    <h1 className="section-title-menu fsizeXSmall fcPrimary">{this.state.secao.titulo}</h1>
                </div>
            )
        } else {
            if(this.props.progresso_modulos.length > 0){
                for (let index = 0; index < this.props.progresso_modulos.length; index++) {
                    if(this.props.progresso_modulos[index].id_modulo === this.props.id_modulo){
                        if(this.props.progresso_modulos[index].progresso_secoes.includes(this.props.id_secao)){
                            return(
                                <div className="section-title-wrapper">
                                    <span className="section-icon section-icon-status-done"></span>
                                    <a onClick={this.changeRoute}><h1 className="section-title-menu fsizeXSmall fcFiveth">{this.state.secao.titulo}</h1></a>
                                </div>
                            )    
                        }
                    }
                }
            } else {
                return(
                    <div className="section-title-wrapper">
                        <span className="section-icon section-icon-status-default"></span>
                        <h1 className="section-title-menu fsizeXSmall fcFiveth">{this.state.secao.titulo}</h1>
                    </div>
                )
            }
        }
        return(
            <div className="section-title-wrapper">
                <span className="section-icon section-icon-status-default"></span>
                <h1 className="section-title-menu fsizeXSmall fcFiveth">{this.state.secao.titulo}</h1>
            </div>
        )
    }
}

export default withRouter(MenuSection);