import React from 'react';
import './About.css';
import logo_slogan from '../../../Image/wid_slogan_preto_e_colorido.png';

class About extends React.Component{
    render(){
        return (
            <>
                <div className='about-course-header'>
                    <h1 className="mb2">Sobre</h1>
                </div>
                <div className='about-content-wrapper'>
                    <div className="about-paragraphs-wrapper">
                        <p>O <b>Wise Infant Development - WID</b> é um software do tipo educacional, desenvolvido para facilitar o processo de ensino-aprendizagem sobre desenvolvimento infantil do lactente. Uma ferramenta tecnológica no <b>âmbito da educação e formação</b> do discente.</p>  
                        <p>A <b>necessidade de qualificação</b> para realização da vigilância do desenvolvimento infantil é uma prioridade, visto que a fase inicial da vida compreende um <b>período de significativas modificações no desenvolvimento da criança</b>, desencadeadora para as fases seguintes e vida adulta. Dessa forma, o WID conta com estratégias inovadoras, propiciando aos estudantes vivências da realidade que os instiguem a refletir, transpondo as fronteiras das disciplinas tradicionais.</p>
                        <p>Um <b>adequado e bom nível de conhecimento</b> sobre o desenvolvimento infantil <b>torna o aluno, futuro profissional, mais capacitado e comprometido</b> com a avaliação da criança na sua totalidade do desenvolvimento infantil, transportando-o para uma prática competente, com conhecimentos e habilidades de excelência, com senso de promoção para saúde da criança.</p>
                        <p>O WID constitui-se no <b>ensino em enfermagem</b> um ambiente flexível e agradável para os estudos, o qual é capaz de <b>instigar</b> raciocínio crítico-reflexivo, <b>solucionar</b> dúvidas, favorável a aquisição de novos conhecimentos, <b>contribuindo</b>, como uma ferramenta de suporte a <b>disciplina de Saúde da Criança administrada em sala de aula</b>, aprimoramento o saber e a associação entre a teoria e a prática.</p>
                    </div>
                    <img className="about-logo-slogan" src={logo_slogan} alt="Logo WID com Slogan"></img>
                </div>
            </>
        );  
    }
}

export default About;