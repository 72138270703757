import React from 'react';
import './TestsMonitoring.css';
import { Link } from 'react-router-dom';
import IconStampMedium from '../../../Image/icon-stamp-m.png';
import IconStampMediumDisabled from '../../../Image/icon-stamp-m-disabled.png';

class About extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            estudante: this.props.estudante
        }
    }

    selectPosTest(estudante){
        if(estudante.pos_teste){
            return (
                <div className="test-monitoring-item">
                    <h1 className="fcPrimary">Pós-Teste</h1>
                    <img className="test-monitoring-img" src={IconStampMedium}></img>
                    <p>Quantidade de acertos</p>
                    <p><b>{estudante.pos_teste_resposta.acertos}/{estudante.pos_teste_resposta.total_respondido}</b> questões</p>
                </div>
            )    
        } else if(estudante.progresso && estudante.progresso.progresso === 100){
            return (
                <div className="test-monitoring-item">
                    <h1 className="fcPrimary">Pós-Teste</h1>
                    <img className="test-monitoring-img" src={IconStampMediumDisabled}></img>
                    <p>Você ainda não fez o Pós-Teste</p>
                    <a className="link-button-test-monit fsizeSmall" href="/teste/pos-teste/introducao">FAZER PÓS-TESTE</a>
                </div>
            )
        }
        return (
            <div className="test-monitoring-item">
                <h1 className="fcPrimary">Pós-Teste</h1>
                <img className="test-monitoring-img" src={IconStampMediumDisabled}></img>
                <p>Finalize os Módulos de</p>
                <p>Ensino para ter acesso</p>
            </div>
        )
    }

    render(){
        return (
            <div className='tests-monitoring-wrapper'>
                <div className="test-monitoring-item">
                    <h1 className="fcPrimary">Pré-Teste</h1>
                    <img className="test-monitoring-img" src={IconStampMedium}></img>
                    <p>Quantidade de acertos</p>
                    <p><b>{this.state.estudante.pre_teste_resposta.acertos}/{this.state.estudante.pre_teste_resposta.total_respondido}</b> questões</p>
                </div>
                { this.selectPosTest(this.state.estudante) }
            </div>
        );  
    }
}

export default About;