import api from './api'
import getToken from '../utils/getToken';

function getLoggedUser(cb){
    
    if(getToken()) {
        api.post('/checktoken', {}, { headers: { authorization: getToken() } }).then(res => {
            if(res.status === 200){
                cb(res.data.userID);
            }else{
                cb(null);
            }
        })
    } else {
        cb(null);
    }
    
} 

export default getLoggedUser;