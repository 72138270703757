import React from 'react';
import './MenuModuleProgressBar.css';
import api from '../../../services/api';
import getLoggedUser from '../../../services/getLoggedUser';

class MenuModuleProgressBar extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            progresso: 0
        }
    }

    componentDidMount() {
        var self = this
        getLoggedUser(function(userID){
            if(userID !== null){
                api.get('/progresso/'+userID+'/modulo/'+self.props.id_modulo).then(res => {
                    if(res.data[0]) {
                        const response = res.data[0];
                        if(response.progresso_modulos[0]){
                            const returnedProgress = response.progresso_modulos[0].progresso;
                            self.setState({ progresso: returnedProgress });
                        }
                    }
                });
            }
        })
    }

    render(){
        if(this.props.progresso_modulos){
            if(this.props.progresso_modulos.length > 0){
                for (let index = 0; index < this.props.progresso_modulos.length; index++) {
                    if(this.props.progresso_modulos[index].id_modulo === this.props.id_modulo){
                        return(
                            <div className="menu-module-progress-bar bcFourth">
                                <div style={ { width: this.props.progresso_modulos[index].progresso+'%' } } className="menu-module-progress-bar-progress bcPrimary"></div>
                            </div>
                        )   
                    }
                }
            } 
        }
        if(this.state.progresso){
            return(
                <div className="menu-module-progress-bar bcFourth">
                    <div style={ { width: this.state.progresso+'%' } } className="menu-module-progress-bar-progress bcPrimary"></div>
                </div>
            )        
        } else {
            return(
                <div className="menu-module-progress-bar bcFourth">
                    <div style={ { width: '0%' } } className="menu-module-progress-bar-progress bcPrimary"></div>
                </div>
            ) 
        }
           
    }
}

export default MenuModuleProgressBar;