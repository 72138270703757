import React from 'react';
import './IntroductionPosTest.css';
import { Link, withRouter } from 'react-router-dom';
import imgBanner from '../../../Image/logo_wid_vertical_colorido.png'; 
import imgIcon from '../../../Image/wid_cubos.png'; 
import api from '../../../services/api';
import getLoggedUser from "../../../services/getLoggedUser";

class ScreenTestIntroductionPosTest extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            usuario: {}
        }
    }

    componentDidMount(){
        let self = this;
        getLoggedUser(function(userID){
            if(userID !== null){
                api.get('/estudante/'+userID).then(res => {
                    self.setState({ usuario: res.data});
                });
            }else{
                self.props.history.push("/");
            }
        });
    }

    render(){
        return(
            <div className="test-introduction-wrapper">
                <img className="logo-top-medium" src={imgBanner}></img><br/>
                <h1 className="t1">PÓS-TESTE</h1>
                <div className="test-introduction-inner">
                    <h1>Olá {this.state.usuario && <b>{this.state.usuario.nome}</b>} <br/>
                    Você concluiu todos os módulos de ensino com sucesso! <br />
                    Antes de ter acesso ao seu certificado de conclusão você realizará um Pós-teste 
                    com a finalidade de medir seu conhecimento sobre Desenvolvimento Infantil do Lactente 
                    após a conclusão do curso.</h1>
                    <Link to={'/teste/pos-teste'} className="link-button-1 mt3 fsizeSmall">CONTINUAR</Link>
                </div>
                <img className="abs-left-bottom" src={imgIcon}></img>
                <img className="abs-right-bottom" src={imgIcon}></img>
            </div>
        )
    }
}

export default withRouter(ScreenTestIntroductionPosTest);