import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import ScreenStudentLogin from './Student/Login/Login';
import ScreenStudentSignUp from './Student/SignUp/SignUp';
import ScreenStudentForgotPassword from './Student/ForgotPassword/ForgotPassword';
import ScreenStudentResetPassword from './Student/ResetPassword/ResetPassword';
import ScreenStudentDashboard from './Student/Dashboard/Dashboard';
import ScreenTestIntroduction from './Test/Introduction/Introduction';
import ScreenTestIntroductionPosTest from './Test/IntroductionPosTest/IntroductionPosTest';
import ScreenTestContainer from './Test/TestContainer/TestContainer';
import ScreenTestFinalized from './Test/Finalized/Finalized';
import ScreenContentContainer from './Content/ContentContainer';
import ScreenModuleFinalized from './Content/ModuleFinalized/ModuleFinalized';
import ScreenCourseFinalized from './Content/CourseFinalized/CourseFinalized';
import ScreenAdminTable from './AdminTable/AdminTable';
import ErroPage from './ErroPage/ErroPage';

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" exact component={ScreenStudentLogin} />
                <Route path="/cadastro" exact component={ScreenStudentSignUp} />
                <Route path="/redefinir/senha" exact component={ScreenStudentForgotPassword} />
                <Route path="/resetar/senha/:token" exact component={ScreenStudentResetPassword} />
                
                <PrivateRoute path="/" exact component={ScreenStudentDashboard} />
                <PrivateRoute path="/teste/pre-teste/introducao" exact component={ScreenTestIntroduction} />
                <PrivateRoute path="/teste/pos-teste/introducao" exact component={ScreenTestIntroductionPosTest} />
                <PrivateRoute path="/teste/:teste_nome" exact component={ScreenTestContainer} />
                <PrivateRoute path="/teste/finalizado/:teste_id/:teste_nome" exact component={ScreenTestFinalized} />
                <PrivateRoute path="/conteudo/curso_finalizado" exact component={ScreenCourseFinalized} />
                <PrivateRoute path="/conteudo/:modulo_id" exact component={ScreenContentContainer} />
                <PrivateRoute path="/conteudo/modulo/finalizado/:id_finalizado/:id_proximo" exact component={ScreenModuleFinalized} />
                <Route path="/admin/table" exact component={ScreenAdminTable} />
                <Route path="*" exact component={ErroPage} />

            </Switch>
        </BrowserRouter>
    );
}