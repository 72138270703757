import React from 'react';
import { withRouter } from 'react-router-dom';
import './Login.css';
import logo from '../../../Image/wid_icone.png';
import api from '../../../services/api';

class PanelLogin extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            email: '',
            senha: '',
            input_errors: {
                email: '',
                senha: ''
            },
            error: ''
        }

        this.handleSubmmit = this.handleSubmmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.blankFields = this.blankFields.bind(this);
    }

    blankFields(){
        if( !this.state.email ||
            !this.state.senha){
            this.setState({
                input_errors: {
                    email:  !this.state.email,
                    senha:  !this.state.senha
                }
            })
            return true;
        }
        return false;
    }

    handleSubmmit(event){
        event.preventDefault();
        if(!this.blankFields()){
            api.post('/auth/authenticate', { email: this.state.email, senha: this.state.senha}).then(res => {
                if(res.status === 200){
                     localStorage.setItem("wid_token", res.data.token);
                    this.props.history.push('/');    
                } else {
                    this.setState({ error: "Não foi possível autenticar" });
                }
                
            }).catch((error) => {
                this.setState({ error: error.response.data.error });
            });
        }else{
            this.setState({
                error: "Preencha todos os campos para fazer login"
            })
        }
        
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState(() => ({
          [name]: value
        }));
    }

    render(){
        return(
            <div className="panel-login">
                <div className="panel-login-header">
                    <img src={logo} alt="WID-Logo"></img>
                    <h4>Bem-Vindo(a) Estudante</h4>
                    <h1>ENTRAR</h1>
                </div>
                {this.state.error && <div className="error-warn">
                    <p>{this.state.error}</p>
                </div>}      
                <form className="panel-login-fields" onSubmit={this.handleSubmmit}>
                    <div className={this.state.input_errors.email ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon"> 
                            <span className="icon-login icon-login-email"></span>
                            <h1>Email:</h1>
                        </div>
                        <div className="input-2-field"> 
                            <input  name="email" 
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange} /> 
                        </div>
                    </div>
                    <div className={this.state.input_errors.senha ? 'input-2 input-2-error' : 'input-2'}>
                        <div className="input-2-icon">
                            <span className="icon-login icon-login-pass"></span>
                            <h1>Senha:</h1>
                        </div>
                        <div className="input-2-field"> 
                            <input  name="senha" 
                                    type="password"
                                    value={this.state.senha}
                                    onChange={this.handleInputChange} /> 
                        </div>
                    </div>
                    <div className="panel-login-button-changePassword">
                        <h1><a href="/redefinir/senha">Redefinir senha</a></h1>
                    </div>
                    <div className="panel-login-button">
                        <button type="submit" className="buttonLogin">ENTRAR</button>
                    </div>
                </form>
                
                <div className="panel-footer">
                    <h1>Ainda não possui cadastro? <a href="/cadastro">Cadastre-se</a></h1>
                </div>
                
            </div>
        )
    }
}

export default withRouter(PanelLogin);