import React from 'react';
import './Question.css';
import renderHTML from 'react-render-html';
import UIButtonPrimary from '../UI/ButtonPrimary/ButtonPrimary';

class Question extends React.Component{
    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeRadio = this.onChangeRadio.bind(this);
        this.formatQuestion = this.formatQuestion.bind(this);
        this.state = {
            selectedItem: ''
        };
    }
    
    handleSubmit(event) {
        event.preventDefault();
        this.props.add_response({id_questao: this.props.question._id,
                                id_resposta: this.state.selectedItem});
        this.setState({selectedItem : ''});
    }

    onChangeRadio(event){
        this.setState({selectedItem : event.target.value});
    }

    formatQuestion(question){
        switch (question.tipo) {
            case 'subdescricao':
                return (
                    <>
                        <p className="question-description"><b>{ question.descricao }</b></p>
                        {question.subdescricao.map((subitem, index) => (
                            <p className="question-description" key={index}>{ subitem }</p>
                        ))}
                    </>
                );
            case 'relacionar':
                    return (
                        <>
                            <p className="question-description"><b>{ question.descricao }</b></p>
                            <div className="question-cols-wrapper">
                                <div className="question-col-left">
                                    {question.coluna_esquerda.map((item, index) => (
                                        <p key={index}>{ item }</p>
                                    ))}
                                </div>
                                <div className="question-col-right">
                                    {question.coluna_direita.map((item, index) => (
                                        <p key={index}>{ item }</p>
                                    ))}
                                </div>
                            </div>
                            <p className="question-description">{ question.subdescricao[0] }</p>
                        </>
                    );
            default:
                return (<p className="question-description"><b>{ question.descricao }</b></p>);    
            
        }
    }
    
    render(){
        if(this.props.question){
            return(
                <>
                    {this.formatQuestion(this.props.question)} 
                    <form className="question-select" onSubmit={this.handleSubmit}>
                        {this.props.question.items.map(item => (
                            <label key={item._id}>
                                <input
                                    type="radio"
                                    name="item"
                                    checked = {this.state.selectedItem === item._id}
                                    onChange = {this.onChangeRadio}
                                    value={item._id}
                                />
                                <b>{item.opcao}</b> {renderHTML(item.descricao)}
                            </label>
                        ))}   
                        <div className="question-button">
                            {this.state.selectedItem ? <UIButtonPrimary text="CONTINUAR"/> : <UIButtonPrimary disabled={true} text="CONTINUAR"/>}
                        </div>
                    </form>  
                </>
            )
        } else {
            return(
                <p className="question-description"><b>Carregando questão...</b></p>
            )  
        }
        
    }
}

export default Question;