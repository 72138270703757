import React from 'react';
import { withRouter } from 'react-router-dom';
import './HeaderBarTop.css';
import logo from '../../../Image/logo_wid_vertical_colorido.png';
import Certificate from '../../Student/Certificate/Certificate';
import EditProfile from '../../Student/EditProfile/EditProfile';

class HeaderBarTop extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            estudante: this.props.estudante
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleClick(content) {
        this.props.switch(content);
    }

    handleLogout() {
        localStorage.removeItem("wid_token");        
        this.props.history.push('/login');

    }

    render(){
        return(
            <div className="header">
                <div className="header-logo">
                    <img src={logo}></img>
                </div>
                <nav className='header-menu'>
                    <ul>
                        <li><a onClick={() => this.handleClick('sobre')}>SOBRE</a></li>
                        <li><a onClick={() => this.handleClick('modulos')}>MÓDULOS DE ENSINO</a></li>
                        <li><a onClick={() => this.handleClick('teste')}>TESTE</a></li>
                        <li>{this.state.estudante && <Certificate estudante={this.state.estudante}/>}</li>
                        <li><a onClick={() => this.handleClick('perguntas')}>PERGUNTAS FREQUENTES</a></li>
                    </ul>
                </nav>
                <div className="header-user">
                    {this.state.estudante && <h1 className="fcPrimary">{this.state.estudante.nome}</h1>}
                    <div className="header-user-options">
                        {this.state.estudante && <EditProfile estudante={this.state.estudante}/>}
                        <div onClick={this.handleLogout} className="header-logout-wrapper">
                            <span>Sair</span>
                            <span className="icon-exit-header"></span>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(HeaderBarTop);