import React from 'react';
import './LoadingData.css'; // Certifique-se de importar o arquivo CSS com os estilos

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="loading-spinner"></div>
            <p className="loading-text">Carregando...</p>
        </div>
    );
};

export default Loading;
