import React from 'react';
import './Finalized.css';
import { Link, withRouter } from 'react-router-dom';
import imgBanner from '../../../Image/wid_vertical_branco_colorido.png';
import imgStamp from '../../../Image/icon-stamp.png';
import api from '../../../services/api';
import getLoggedUser from "../../../services/getLoggedUser";

class ScreenTestFinalized extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            usuario: {},
            acertos: 0,
            total_questoes: 0,
            teste_id: this.props.match.params.teste_id,
            teste_nome: this.props.match.params.teste_nome
        }
    }

    componentDidMount(){
        let self = this;
        getLoggedUser(function(userID){
            if(userID !== null){
                api.get('/estudante/'+userID).then(res => {
                    self.setState({ usuario: res.data});
                });
                api.get('/estudante/'+userID+'/resultado/'+self.state.teste_id).then(res => {
                    self.setState({acertos: res.data.acertos, total_questoes: res.data.total});
                });
                
            }else{
                self.props.history.push("/");
            }
        });
        
    }

    render(){
        return(
            <div className="test-finalized-wrapper">
                <img className="logo-top-medium" src={imgBanner}></img>
                <div className="test-finalized-inner">
                    <img className="icon-stamp" src={imgStamp}></img>
                    <div className="test-finalized-text">
                        <h1>Parabéns {this.state.usuario && <b>{this.state.usuario.nome}</b>}</h1>
                        <h2>Você finalizou o <b>{this.state.teste_nome && (this.state.teste_nome === 'pre-teste' ? 'Pré-Teste' : 'Pós-Teste')}</b> com sucesso!</h2>
                        <br/>
                        <h2>{this.state.teste_nome && (this.state.teste_nome === 'pre-teste' ? 'Agora você já pode começar a estudar o conteúdo' : 'Agora você já pode emitir seu certificado!')}</h2>
                        <br/>
                        {this.state.acertos ?  <h2>Quantidade de acertos: {this.state.acertos}/{this.state.total_questoes} questões</h2> : <h2>Carregando resultado do teste...</h2>}
                       
                    </div>
                    
                </div>
                { this.state.acertos ? <Link to={'/'} className="link-button-1 mt3 fsizeSmall">CONTINUAR</Link> : '' }
            </div>
        )
    }
}

export default withRouter(ScreenTestFinalized);