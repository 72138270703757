
import React from 'react';
import './SectionSidebar.css';
import staticConfig from '../../../constants';
import api from '../../../services/api';
import { withRouter } from "react-router-dom";
import renderHTML from 'react-render-html';

class SectionSidebar extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            sub_gal_ind: 0,
            vg_ind: 0,
            ig_ind: 0,
            secao: {},
        }

        this._isMounted = false; 
        this.adaptExtraContent = this.adaptExtraContent.bind(this);
        this.selectExtraContent = this.selectExtraContent.bind(this);
        this.nextItem = this.nextItem.bind(this);
        this.previousItem = this.previousItem.bind(this);
        this.vgNext = this.vgNext.bind(this);
        this.vgPrevious = this.vgPrevious.bind(this);
        this.igNext = this.igNext.bind(this);
        this.igPrevious = this.igPrevious.bind(this);
    }

    componentDidMount() {
        this._isMounted = true; 
        api.get('/curso/' + staticConfig.course_id + '/modulo/' + this.props.id_modulo + '/secao/' + this.props.id_secao)
            .then(res => {
                if (this._isMounted) { 
                    const secao = res.data;
                    this.setState({ secao });
                }
            })
            .catch(error => {
                console.error('Erro ao carregar dados:', error);
            });
    }

    componentWillUnmount() {
        this._isMounted = false; 
    }

    nextItem(){
        this.setState({
            sub_gal_ind: this.state.sub_gal_ind + 1
        });
    }

    previousItem(){
        this.setState({
            sub_gal_ind: this.state.sub_gal_ind - 1
        });    
    }

    vgNext(){
        this.setState({
            vg_ind: this.state.vg_ind + 1
        });
    }

    vgPrevious(){
        this.setState({
            vg_ind: this.state.vg_ind - 1
        });    
    }

    igNext(){
        this.setState({
            ig_ind: this.state.ig_ind + 1
        });
    }

    igPrevious(){
        this.setState({
            ig_ind: this.state.ig_ind - 1
        });    
    }

    adaptExtraContent(conteudo_extra) {
        switch(conteudo_extra.tipo) {
            case 'imagem':
                return (
                    <img className="img-extra-content" src={conteudo_extra.image_url} alt={conteudo_extra.conteudo}></img>
                );
            case 'video':
                return (
                    <>
                        <div className="video-extra-content bcFiveth">
                            <iframe src={conteudo_extra.conteudo}></iframe>
                            <p className="video-extra-content-paragraph">{conteudo_extra.descricao}</p>
                        </div>
                    </>
                );
            case 'paragrafo':
                return (
                    <div className="paragrafo-extra-content bcFiveth">
                        <p className="paragrafo-extra-content-paragraph">{conteudo_extra.conteudo}</p>
                    </div>
                );
            case 'paragrafo-atencao':
                    return (
                        <div className="paragrafo-at-extra-content bcFiveth">
                            <p className="paragrafo-at-extra-content-title fcPrimary">{conteudo_extra.descricao}</p>
                            <div className="paragrafo-at-extra-content-paragraph">{renderHTML(conteudo_extra.conteudo)}</div>
                        </div>
                    );
            case 'imagem-links':
                    return (
                        <div className="imagem-links-content bcFiveth">
                            <div className="imagem-links-img-wrapper">
                                {conteudo_extra.imagem_links.map((image_link) => (
                                    <a key={image_link._id} href={image_link.arquivo_url} target="_blank">
                                        <img className={image_link.horizontal ? "imagem-links-img-horizontal" : "imagem-links-img"} 
                                        src={image_link.imagem_url} 
                                        alt={image_link.imagem}></img>
                                    </a>    
                                ))}
                            </div>
                            <p className="imagem-links-descricao">{conteudo_extra.descricao}</p>
                        </div>
                    );
            case 'video-item-galeria':
                    return (
                        <>
                            <div className="video-extra-content bcFiveth">
                                {this.state.vg_ind > 0 && <span onClick={this.vgPrevious} className="vg-arrow vg-arrow-left">&lt;</span>}
                                {this.state.vg_ind < conteudo_extra.video_galeria.length - 1 && <span onClick={this.vgNext} className="vg-arrow vg-arrow-right">&gt;</span>}
                                <iframe src={conteudo_extra.video_galeria[this.state.vg_ind].link}></iframe>
                                <p className="video-extra-content-paragraph">{conteudo_extra.video_galeria[this.state.vg_ind].descricao}</p>
                            </div>
                            <div className="ig-extra-content bcFiveth">
                                {this.state.ig_ind > 0 && <span onClick={this.igPrevious} className="sgi-arrow sgi-arrow-left">&lt;</span>}
                                {this.state.ig_ind < conteudo_extra.item_galeria.length - 1 && <span onClick={this.igNext} className="sgi-arrow sgi-arrow-right">&gt;</span>}
                                <p className="paragrafo-at-extra-content-title fcPrimary">{conteudo_extra.item_galeria[this.state.ig_ind].titulo}</p>
                                <div className="ig-extra-content-content">
                                    {renderHTML(conteudo_extra.item_galeria[this.state.ig_ind].conteudo)}
                                </div>
                            </div>
                        </>
                    );
            case 'sub-imagem':
                    return (
                        <img className="img-extra-content" src={conteudo_extra.conteudo[0] && conteudo_extra.conteudo[0].image_url} alt={conteudo_extra.conteudo[0] && conteudo_extra.conteudo[0].descricao}></img>
                    );
            case 'sub-galeria-imagem':
                if(conteudo_extra.conteudo[this.state.sub_gal_ind]){
                    return (
                        <div className="sgi-wrapper bcFiveth">
                            {this.state.sub_gal_ind > 0 && <span onClick={this.previousItem} className="sgi-arrow sgi-arrow-left">&lt;</span>}<span className="sgi-title fcSecundary">{conteudo_extra.conteudo[this.state.sub_gal_ind].titulo}</span>{this.state.sub_gal_ind < conteudo_extra.conteudo.length - 1 && <span onClick={this.nextItem} className="sgi-arrow sgi-arrow-right">&gt;</span>}
                            <div className="sgi-content">
                                <p>{conteudo_extra.conteudo[this.state.sub_gal_ind].descricao}</p>
                                {conteudo_extra.conteudo[this.state.sub_gal_ind].conteudo && <img className="img-extra-content" src={conteudo_extra.conteudo[this.state.sub_gal_ind].image_url} alt={conteudo_extra.conteudo[this.state.sub_gal_ind].titulo}></img>}
                            </div>
                        </div>
                    );
                } else {
                    if(conteudo_extra.conteudo[0]){
                        this.setState({
                            sub_gal_ind: 0
                        }); 
                        return (
                            <div className="sgi-wrapper bcFiveth">
                                <span className="sgi-title fcSecundary">{conteudo_extra.conteudo[0].titulo}</span><span onClick={this.nextItem} className="sgi-arrow sgi-arrow-right">&gt;</span>
                                <div className="sgi-content">
                                    <p>{conteudo_extra.conteudo[0].descricao}</p>
                                    {conteudo_extra.conteudo[0].conteudo && <img className="img-extra-content" src={conteudo_extra.conteudo[0].image_url} alt={conteudo_extra.conteudo[0].titulo}></img>}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <div className="sgi-wrapper bcFiveth"></div>
                    );    
                }
                
            default:
                return (
                    <p className="paragraph-extra-content">{renderHTML(conteudo_extra.conteudo)}</p>
                );
        }
    }

    selectExtraContent(){
        if(this.state.secao.subsecoes && this.state.secao.subsecoes.length > 0){
            return (
                <div className="section-extra-content">
                    {this.state.secao.subsecoes[this.props.sub_index].conteudo_extra &&
                      this.adaptExtraContent(this.state.secao.subsecoes[this.props.sub_index].conteudo_extra)   
                    }
                </div> 
            )
        }else{
            return (
                <div className="section-extra-content">
                    {this.state.secao.conteudo_extra && this.adaptExtraContent(this.state.secao.conteudo_extra)}
                </div>
            )
        }
    }

    render(){
        return (
            <>
                {this.selectExtraContent()}
                {this.state.secao.referencias && this.state.secao.referencias.length > 0 && <div className="section-references bcFiveth">
                    <h4>REFERÊNCIAS</h4>
                    <div className="section-references-wrapper">
                        {this.state.secao.referencias.map((referencia, index) => (
                            <p key={index} className="section-reference">{referencia}</p>
                        ))}
                    </div>
                </div> }
            </>
        )
    }
}

export default withRouter(SectionSidebar);