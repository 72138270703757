import React from 'react';
import './TestContainer.css';
import api from '../../../services/api';
import insertAuth from '../../../services/insertAuth';
import getLoggedUser from '../../../services/getLoggedUser';
import imgBanner from '../../../Image/logo_wid_vertical_colorido.png'; 
import imgIcon from '../../../Image/wid_cubos.png'; 
import Question from '../../../Components/Test/Question';
import { withRouter } from "react-router-dom";

class ScreenTestContainer extends React.Component{
    constructor(props){
        super(props);

        this.getTest = this.getTest.bind(this);
        this.getUserId = this.getUserId.bind(this);
        this.addResponse = this.addResponse.bind(this);
        this.sendTestResponse = this.sendTestResponse.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.incrementProgressBar = this.incrementProgressBar.bind(this);
        this.shuffleQuestoes = this.shuffleQuestoes.bind(this);

        this.state = {
            proximaQuestao: 0,
            questaoAtual: {},
            estudante: {},
            user_id: '',
            teste_id: '',
            teste_nome: '',
            teste_nome_mostrar: '',
            questoes: [],
            respostasQuestoes: []
        };
    }

    shuffleQuestoes() {
        var array = this.state.questoes;
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        this.setState({ questoes: array });
        }

    async getTest() {
        const t_nome = this.props.match.params.teste_nome;
        const res = await api.get('/teste/'+t_nome, insertAuth());
        const teste = res.data;
        this.setState(() => ({  questoes: teste.questoes,
                                teste_id: teste._id,
                                teste_nome: t_nome,
                                teste_nome_mostrar: teste.nome_mostrar }), () => {
            this.incrementProgressBar();
            if(this.state.teste_nome === 'pos-teste'){
                if(this.state.estudante.progresso){
                    if(this.state.estudante.progresso.progresso < 100){
                        this.props.history.push('/');    
                    }
                } else {
                    this.props.history.push('/');    
                }
                this.shuffleQuestoes();
            } else if(this.state.teste_nome === 'pre-teste'){
                if(this.state.estudante.pre_teste){
                    this.props.history.push('/');
                }
            }
        });
    }

    getUserId() {
        let self = this;
        getLoggedUser(function(userID){
            self.setState(({ user_id: userID}), () => {
                api.get('/estudante/'+self.state.user_id).then(res => {
                    self.setState({ estudante: res.data });
                });
            });
        });
    }

    componentDidMount() {
        this.getUserId();
        this.getTest();
    }

    addResponse(resposta) {
        this.setState({
            respostasQuestoes: [...this.state.respostasQuestoes, resposta]
        }, () => {
            this.nextQuestion();
        });
        
    }

    sendTestResponse(){
        var responder = {
            nome_teste: this.state.teste_nome,
            testResponse: {
                teste: this.state.teste_id,
                id_usuario: this.state.user_id,
                respostas: this.state.respostasQuestoes
            }
        }
        api.post('teste/response', responder, insertAuth());
    }

    nextQuestion() {
        if(this.state.proximaQuestao < (this.state.questoes.length - 1)){
            this.setState((state) => ({
                proximaQuestao: state.proximaQuestao + 1
            }), () => {
                this.incrementProgressBar();
                this.render();
            });
        } else {
            this.sendTestResponse();
            this.props.history.push('/teste/finalizado/'+this.state.teste_id+'/'+this.state.teste_nome);
        }
    }

    incrementProgressBar() {
        var atual = this.state.proximaQuestao + 1;
        var total = this.state.questoes.length;
        var elem = document.getElementsByClassName("test-progress-bar-progress")[0];
        var porcentagem = atual / total * 100;
        elem.style.width = porcentagem + "%";
    }

    render(){
        var questao = this.state.questoes[this.state.proximaQuestao];  
        return(
            <div className="test-container-wrapper">
                <img className="logo-top-medium" src={imgBanner}></img><br/>
                <h1 className="t1">{this.state.teste_nome_mostrar}</h1>
                <div className="test-question-wrapper">
                    {questao && <Question key={questao._id} question={questao} add_response={this.addResponse} />}
                </div>
                <img className="abs-left-bottom" src={imgIcon}></img>
                <img className="abs-right-bottom" src={imgIcon}></img>
                <div className="test-progress-bar bcFourth">
                    <div className="test-progress-bar-progress bcPrimary"></div>
                </div>
            </div>
        )
    }
}

export default withRouter(ScreenTestContainer);