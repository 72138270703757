import React from 'react';
import './FrequentQuestions.css';

class FrequentQuestions extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            question: undefined
        }

        this.switchAnswers = this.switchAnswers.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    
    switchAnswers(){
        switch (this.state.question) {
            case 1:
                return (
                    <div className="faq-answer">
                        Primeiramente, você irá se cadastrar preenchendo os dados cobrados na tela de cadastro do aluno. Será enviado um código para o e-mail informado no ato do cadastro. Vá até seu e-mail, confira o código enviado, e digite-o no campo obrigatório “código de verificação” ainda na tela de cadastro. Após esse passo, pronto! Você poderá realizar login no software com usuário e senha criados. Em caso de dúvidas, envie e-mail para <b>suporteWID@gmail.com.</b>
                    </div>
                );
            case 2:
                    return (
                    <div className="faq-answer">
                        Não. Os horários ficam a critério do aluno. Todavia, é importante lembrar do prazo para realização de todas atividades no software, e o aluno montar uma programação de acesso diário a qual se encaixe com suas outras atividades, não comprometendo seu rendimento.
                    </div>
                );
            case 3:
                    return (
                    <div className="faq-answer">
                        Você não precisa ter grandes conhecimentos em informática para fazer todo curso. Basta ter conhecimento básico e disposição para aprender!    
                    </div>
                );
            case 4:
                    return (
                    <div className="faq-answer">
                        O aluno tem um prazo de 30 dias (após o início das atividades) para concluir todos os módulos, realização da avaliação final e posterior emissão do certificado.    
                    </div>
                );
            case 5:
                    return (
                    <div className="faq-answer">
                        Sim. É possível o aluno passar para o módulo seguinte sem concluir o anterior, tendo em vista que o conteúdo abordado é dividido em domínios que se complementam, não havendo a obrigatoriedade de uma sequência ou pré-requisito, com exceção do Módulo I - Introdução. Na tela principal de cada módulo haverá o progresso do aluno nas atividades até 100% de sua finalização.       
                    </div>
                );
            case 6:
                    return (
                    <div className="faq-answer">
                        Sim. O aluno pode voltar a qualquer momento para um módulo já finalizado caso queira rever ou relembrar algum conteúdo abordado.    
                    </div>
                );
            case 7:
                    return (
                    <div className="faq-answer">
                        Não. O intuito do pré-teste é servir como uma avaliação prévia para medir seu conhecimento acerca do desenvolvimento infantil do lactente antes de passar por todo curso, não inferindo em nada negativo para o aluno.      
                    </div>
                );
            case 8:
                    return (
                    <div className="faq-answer">
                        É uma avaliação final após o aluno concluir todos os módulos de ensino, com a finalidade de medir seu conhecimento após intervenção, e diante do seu rendimento servir como um meio para emissão do certificado.    
                    </div>
                );
            case 9:
                    return (
                    <div className="faq-answer">
                        Não. Para obtenção do certificado é necessária a conclusão de todos os módulos + a realização do pós-teste (avaliação final).     
                    </div>
                );
            case 10:
                    return (
                    <div className="faq-answer">
                        Não. O certificado é emitido gratuitamente.   
                    </div>
                ); 
            default:
                return ('');
        }
    }

    handleClick(qnumber){
        this.setState({
            question: qnumber
        })
    }

    render(){
        return (
            <div className='faq-wrapper'>
                <div className='faq-header'>
                    <h1 className="mb2">Perguntas Frequentes</h1>
                </div>
                <div className='faq-content-wrapper'>
                    <div className="faq-questions-wrapper">
                        <p className={this.state.question === 1 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(1)}>01. O que devo fazer para acessar o conteúdo do software?</p>
                        <p className={this.state.question === 2 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(2)}>02. O curso tem horário fixo para realizá-lo?</p>
                        <p className={this.state.question === 3 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(3)}>03. Quais desafios posso enfrentar com essa tecnologia?</p>
                        <p className={this.state.question === 4 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(4)}>04. Qual a duração ou prazo para concluir o curso?</p>
                        <p className={this.state.question === 5 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(5)}>05. Posso passar para o próximo módulo sem ter concluído o anterior?</p>
                        <p className={this.state.question === 6 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(6)}>06. Posso voltar para um conteúdo já concluído?</p>
                        <p className={this.state.question === 7 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(7)}>07. Posso visualizar as respostas do Pré-teste?</p>
                        <p className={this.state.question === 8 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(8)}>08. O que é pós-teste? Qual sua finalidade? </p>
                        <p className={this.state.question === 9 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(9)}>09. Posso obter o certificado sem ter terminado todos os módulos de ensino?</p>
                        <p className={this.state.question === 10 ? 'faq-question faq-question-selected' : 'faq-question'} onClick={() => this.handleClick(10)}>10. Preciso pagar algo para receber o certificado?</p>
                    </div>
                    <div className="faq-answers-wrapper">
                        {this.switchAnswers()}
                    </div>
                </div>
            </div>
        );  
    }
}

export default FrequentQuestions;