import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import getToken from '../utils/getToken';
import api from '../services/api';
import Loading from '../Components/UI/LoadingData/LoadingData';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const token = getToken();
        if(token){
            api.post('/checktoken', {}, { headers: { authorization: token } })
                .then(res => {
                    if(res.status === 200){
                        setAuthenticated(true);
                    } else {
                        localStorage.removeItem('wid_token');
                        setAuthenticated(false);
                    }
                })
                .catch(err => {
                    localStorage.removeItem('wid_token');
                    setAuthenticated(false);
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <Loading />
    }

    return (
        <Route {...rest} render={props => {
            if(authenticated){
                return <Component {...props} />;
            } else {
                return <Redirect to={{ pathname: "/login", state: { from: props.location } }}/>;   
            }
        }} />
    );
};
