import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import './Login.css';
import imgBanner from '../../../Image/wid_vertical_branco_colorido.png'; 
import PanelLogin from '../../../Components/Student/Login/Login';
import getToken from '../../../utils/getToken'; 
import Loading from '../../../Components/UI/LoadingData/LoadingData'; 

const ScreenStudentLogin = () => {
    const [redirect, setRedirect] = useState(false);
    const [tokenExists, setTokenExists] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = getToken();
        if (token) {
            setTokenExists(true);
        } else {
            setTokenExists(false);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (tokenExists) {
            setRedirect(true);
        }
    }, [tokenExists]);

    if (loading) {
        return <Loading />; 
    }

    if (redirect) {
        return <Redirect to="/" />;
    }

    if (tokenExists) {
        return null; 
    }

    return (
        <div className="login-page-wrapper">
            <div className="login-page-banner">
                <img src={imgBanner} alt="Banner"></img>
                <h1>Desenvolvimento infantil do Lactente de <br/>
                    um jeito dinâmico, interativo e inovador</h1>
            </div>
            <PanelLogin/>
        </div>
    );
}

export default ScreenStudentLogin;
