import React from 'react';
import './SignUp.css';

import imgBanner from '../../../Image/wid_vertical_branco_colorido.png'; 

import PanelSignUp from '../../../Components/Student/SignUp/SignUp';

class ScreenStudentSignUp extends React.Component{
    render(){
        return(
            <div className="singup-page-wrapper">
                <div className="singup-page-banner">
                    <img src={imgBanner}></img>
                    <h1>Desenvolvimento infantil do Lactente de <br/>
                        um jeito dinâmico, interativo e inovador</h1>
                </div>
                <PanelSignUp/>
            </div>
        )
    }
}

export default ScreenStudentSignUp;