import React from 'react';
import { withRouter } from "react-router-dom";
import './ResetPassword.css';
import UIButtonPrimary from '../../../Components/UI/ButtonPrimary/ButtonPrimary';
import api from '../../../services/api';

class PanelResetPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            senha: '',
            conf_senha: '',
            token: this.props.match.params.token,
            error: '',
            enviado: false,
            mensagem: '',
            input_errors: {
                email: '',
                senha: '',
                conf_senha: ''   
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.blankFields = this.blankFields.bind(this);
        this.checkMatch = this.checkMatch.bind(this);
        this.selectRedefinir = this.selectRedefinir.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        if(!this.blankFields()){
            var body = { email: this.state.email,
                         senha: this.state.senha,
                         token: this.state.token }
            api.post('/auth/reset_password', body).then(res => {
                if(res.status === 201){
                    this.setState({
                        enviado: true,
                        mensagem: res.data.mensagem
                    });  
                } else {
                    this.setState({
                        error: "Erro ao criar nova senha, por favor tente novamente"
                    })     
                }
            }).catch((error) => {
                this.setState({
                    error: error.response.data.error
                });
            });    
                    
        } else {
            this.setState({
                error: "Por favor preencha todos os campos"
            })
        }
    }

    blankFields(){
        if( !this.state.email ||
            !this.state.senha ||
            !this.state.conf_senha){
            this.setState({
                input_errors: {
                    email:  !this.state.email,
                    senha: !this.state.senha,
                    conf_senha: !this.state.conf_senha
                }
            })
            return true;
        }
        return false;
    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState(() => ({
          [name]: value
        }), () => {
            this.checkMatch();
        });
    }

    checkMatch() {
        if(this.state.senha && this.state.conf_senha){
            if(this.state.senha === this.state.conf_senha){
                this.setState(() => ({
                    error: ''
                }))    
            }     
        } 
        if(this.state.senha && this.state.conf_senha){
            if(this.state.senha !== this.state.conf_senha){
                this.setState(() => ({
                    error: "Confirmação de senha não corresponde"
                }))   
            }  
        } 
    }

    selectRedefinir(){
        if(this.state.enviado){
            return (
                <div className="panel-reseted-done">
                    <span className="icon-reseted-password"></span>
                    <h2>{this.state.mensagem.toUpperCase()}</h2>
                    <a href="/">Realizar Login</a>
                </div>
            )
        }else{
            return (
                <>
                    <div className="panel-reset-header">
                        <h1>CRIAR NOVA SENHA</h1>
                        <h4>Informe seu email a nova senha e a confirmação da nova senha</h4>
                    </div>
                    {this.state.error && <div className="error-warn">
                        <p>{this.state.error}</p>
                    </div>}               
                    <form onSubmit={this.handleSubmit} className="panel-reset-fields">
                        <div className={this.state.input_errors.email  ? 'input-2 input-2-error' : 'input-2'}>
                            <div className="input-2-icon">
                                <span className="icon-reset icon-reset-email"></span><h1>Email:</h1>
                            </div>
                            <div className="input-2-field">
                                <input name="email" type="email" value={this.state.email} onChange={this.handleInputChange}/> 
                            </div>
                        </div>
                        <div className={this.state.input_errors.senha || (this.state.senha && this.state.conf_senha && (this.state.senha !== this.state.conf_senha)) ? 'input-2 input-2-error' : 'input-2'}>
                            <div className="input-2-icon">
                                <span className="icon-reset icon-reset-pass"></span><h1>Senha:</h1>
                            </div>
                            <div className="input-2-field">
                                <input name="senha" type="password" value={this.state.senha} onChange={this.handleInputChange}/> 
                            </div>
                        </div>
                        <div className={this.state.input_errors.conf_senha || (this.state.senha && this.state.conf_senha && (this.state.senha !== this.state.conf_senha)) ? 'input-2 input-2-error' : 'input-2'}>
                            <div className="input-2-icon">
                                <span className="icon-reset icon-reset-pass"></span><h1>Confirmar Senha:</h1>
                            </div>
                            <div className="input-2-field">
                                <input name="conf_senha" type="password" value={this.state.conf_senha} onChange={this.handleInputChange}/> 
                            </div>
                        </div>
                        <div className="panel-reset-button">
                            <UIButtonPrimary text="SALVAR"/>
                        </div>
                    </form>   
                </>    
            )
        }
    }

    render(){
        return(
            <div className="panel-reset">
                { this.selectRedefinir() }          
            </div>
        )
    }
}

export default withRouter(PanelResetPassword);