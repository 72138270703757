import React from 'react';
import './Certificate.css';
import { PDFDownloadLink } from '@react-pdf/renderer';
import staticConfig from '../../../constants';
import api from '../../../services/api';
import insertAuth from '../../../services/insertAuth';
import CertificateDocument from './CertificateDocument';

class StudentCertificate extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            estudante: this.props.estudante,
            curso: undefined,
            clicked: false,
        }

        this.showNot = this.showNot.bind(this);
        this.notShow = this.notShow.bind(this);
    }
    
    componentDidMount() {
        api.get('/curso/'+staticConfig.course_id, insertAuth()).then(res => {
            this.setState({
                curso: res.data    
            })
        });    
    }

    notShow(){
        this.setState({
            clicked: false
        });
    }

    showNot(){
        this.setState({
            clicked: true
        }); 
    }

    render(){

        if(this.state.estudante && this.state.curso && this.state.estudante.pos_teste && this.state.estudante.certificado){
            return(
                <PDFDownloadLink document={<CertificateDocument estudante={this.state.estudante} curso={this.state.curso}/>} fileName="certificado.pdf">
                    {({ blob, url, loading, error }) => (loading ? 'CERTIFICADO...' : 'CERTIFICADO')}
                </PDFDownloadLink>
            )
        } else if(this.state.estudante && !this.state.estudante.pos_teste){
            if(this.state.clicked){
                return( 
                    <>
                        <a>CERTIFICADO</a>
                        <div onClick={this.notShow} id='certificate-not' className="show">
                            <div className='certificate-not-alert-wrapper'>
                                <div className='certificate-not-alert'>
                                    <span className='icon-certificate-atention'></span>
                                    <p>Para obtenção do certificado é necessária a conclusão de todos os módulos + a realização do pós-teste (avaliação final)</p>
                                </div>
                            </div>
                        </div>
                    </>  
                )      
            } else {
                return(<a onClick={this.showNot}>CERTIFICADO</a>)
            }
        } else {
            return(
                <a>...</a>
            )        
        }
    }
}

export default StudentCertificate;