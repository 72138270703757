import React from 'react';
import './ErroPage.css'; // Importando o arquivo CSS
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ErroPage() {


    
  return (
    <div className="error-container">
      <h1 className="error-heading">Erro 404</h1>
      <p className="error-message">A página que você está procurando não foi encontrada.</p>
      {/* <p className="error-code">Código de erro: 404</p> */}
      <div className="button-container">
        <Link to="/" className="button">Página Inicial</Link>
      </div>
    </div>
  );
}

export default ErroPage;
