import React from 'react';
import './DashboardModule.css';

class DashboardModule extends React.Component{
    constructor(props){
        super(props);

        this.formatLink = this.formatLink.bind(this);
        this.formatProgressDetails = this.formatProgressDetails.bind(this);
    }
    
    formatLink(){
        var modulo = this.props.modulo;
        var id_modulo = modulo._id;
        var id_secao = modulo.secoes[0];
        return '/conteudo/modulo/'+id_modulo+'/secao/'+id_secao;
    }

    formatProgressDetails(){
        if(this.props.progresso){
            if(this.props.progresso.progresso === 100) {
                return (
                    <>
                        <div className="module-progress-wrapper">
                            <span className="module-progress-icon-done"></span>
                            <span className="module-progress-numbers fcSixth">Concluído</span>
                        </div>
                        <div className="module-progress-bar bcFourth">
                            <div style={ { width: '100%' } } className="module-progress-bar-progress bcSixth"></div>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className="module-progress-wrapper">
                            <span className="module-progress-icon"></span>
                            <span className="module-progress-numbers">{this.props.progresso.progresso_secoes.length} de {this.props.modulo.secoes.length}</span>
                        </div>
                        <div className="module-progress-bar bcFourth">
                            <div style={ { width: this.props.progresso.progresso+'%' } } className="module-progress-bar-progress bcPrimary"></div>
                        </div>
                    </>
                )
            }
        } else {
            return (
                <>
                    <div className="module-progress-wrapper">
                            <span className="module-progress-icon"></span>
                            <span className="module-progress-numbers">0 de {this.props.modulo.secoes.length}</span>
                        </div>
                    <div className="module-progress-bar bcFourth">
                        <div style={ { width: '0%' } } className="module-progress-bar-progress bcPrimary"></div>
                    </div>
                </>
            )   
        }
    }

    render(){
        return(
            <div className='module-container'>
                <div className='module-header'>
                    <h4>{this.props.modulo.nome}</h4>
                    <div className="module-details">
                        <h1>{this.props.modulo.descricao}</h1>
                        <span className="module-info-icon" title={this.props.modulo.info}></span>
                    </div>            
                </div>
                <div className="module-image">
                   <a href={"/conteudo/"+this.props.modulo._id}><img src={this.props.modulo.image_url}></img></a>
                </div>
                <div className="module-progress-details">
                    { this.formatProgressDetails() }
                </div>
            </div>
        )
    }
}

export default DashboardModule;